html,
body {
  height: 100%;
}

body {
  font-family: "Lucida Sans Typewriter", "Lucida Console", monaco,
    "Bitstream Vera Sans Mono", monospace;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
